import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@layouts/default-layout';
import SEO from '@components/seo';
import renderModule from '@components/module';
import generateId from '@helpers/generate-id';
import { MainPageInterface, Container } from '@pages/index';

const pageIdGenerator = generateId();

interface PageProps {
  data: MainPageInterface;
}

const Page = ({ data }: PageProps): JSX.Element => {
  const {
    allPrismicPage,
    prismicGeneralConfig: {
      data: { favicon },
    },
  }: MainPageInterface = data;

  const { page_title, meta_description, meta_image } = allPrismicPage.nodes[0].data;

  return (
    <Layout layoutType={allPrismicPage.nodes[0].data.layout_type}>
      <SEO
        title={page_title.text}
        description={meta_description.text}
        image={meta_image}
        faviconHref={favicon?.url || ''}
      />
      <Container>
        {allPrismicPage.nodes[0].data.body.map(
          (module) =>
            module && (
              <React.Fragment key={pageIdGenerator.next().value}>
                {renderModule(
                  module,
                  allPrismicPage.nodes[0].last_publication_date,
                  allPrismicPage.nodes[0].data.secondary_nav
                )}
              </React.Fragment>
            )
        )}
      </Container>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  query PageQuery($uid: String) {
    prismicGeneralConfig {
      ...GeneralConfigFragment
    }
    allPrismicPage(filter: { uid: { eq: $uid, ne: "home" } }) {
      nodes {
        ...PageFragment
      }
    }
  }
`;
