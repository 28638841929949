import React, { ReactNode } from 'react';
import { defaultTheme } from '@styles/theme.styled';
import { StyledSection, Content } from './section.styled';

interface SectionProperties {
  children: ReactNode;
  backgroundColor: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
  backgroundAlignment?: string;
  displayBorder?: boolean;
  className?: string;
  anchorId?: string;
}

const Section = ({
  anchorId = '',
  className = '',
  children,
  backgroundColor = 'white',
  outerbackgroundColor = 'white',
  backgroundAlignment = 'hidden',
  displayBorder = false,
}: SectionProperties): JSX.Element => {
  const classes = `${
    displayBorder ? 'gradient-border' : ''
  } section-${backgroundColor} ${className}`;

  return (
    <StyledSection
      backgroundColor={backgroundColor}
      backgroundAlignment={backgroundAlignment}
      outerbackgroundColor={outerbackgroundColor}
      displayBorder={displayBorder}
      className={classes}
      id={anchorId}
    >
      <Content>{children}</Content>
    </StyledSection>
  );
};

export default Section;
