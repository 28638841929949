import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  backgroundColors: {
    transparent: 'transparent',
    white: '#FFFFFF',
    light: '#F5F1EB',
    dark: '#A19A9E',
    primary: '#AA1EF5',
    gradient: 'linear-gradient(to bottom right, #A3E7FF, #AA1EF5, #3D1152, #3D1152)',
  },

  borderRadius: {
    small: '0.5rem',
    normal: '1rem',
    large: '2rem',
  },

  fontFamily: {
    display: 'Archia, Verdana, sans-serif',
    text: 'Silka, Arial, sans-serif',
    additional: '"IBM Plex Sans", Tahoma, sans-serif',
  },

  fontSize: {
    tooltip: { desktop: '0.75rem', mobile: '0.75rem' },
    extraSmall: { desktop: '0.75rem', mobile: '0.75rem' },
    small: { desktop: '0.875rem', mobile: '0.8375rem' },
    body: { desktop: '1.125rem', mobile: '0.875rem' },
    large: { desktop: '1.25rem', mobile: '0.9375rem' },
    eyebrow: { desktop: '0.875rem', mobile: '0.8125rem' },
    subheading: { desktop: '1.5rem', mobile: '1.0625rem' },
    heading6: { desktop: '1.75rem', mobile: '1.25rem' },
    heading5: { desktop: '2rem', mobile: '1.375rem' },
    heading4: { desktop: '3rem', mobile: '1.75rem' },
    heading3: { desktop: '3.5rem', mobile: '1.875rem' },
    heading2: { desktop: '5rem', mobile: '2rem' },
    heading1: { desktop: '6rem', mobile: '2.25rem' },
  },

  fontWeights: {
    normal: 'normal',
    bold: 'bold',
  },

  lineHeights: {
    xs: '1',
    sm: '1.125',
    base: '1.25',
    lg: '1.5',
    xl: '1.75',
  },

  spacing: {
    xxs: '0.25rem',
    xs: '0.5rem',
    sm: '1rem',
    md: '2rem',
    lg: '4rem',
    xl: '6rem',
    xxl: '8rem',
    xxxl: '10rem',
    columnGap: '2rem',
  },

  padding: {
    xs: '1rem',
    sm: '2rem',
    md: '3rem',
    lg: '4rem',
    xl: '6rem',
    cards: {
      sm: '2rem',
      md: '4rem',
      lg: '6rem',
    },
  },

  colors: {
    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#070D19',
    primary: '#ee3932',
    primaryLight: '#F6817E',
    secondary: '#F6817E',
    tertiary: '#44C8E1',
    accent: '#FCDB42',
    accentSecondary: '#5C257B',
    dark: '#13294e',
    light: '#C7EAF2',
    muted: '#999999',
    lightGray: '#F2F2F2',
    lightBlue: '#A3E7FF',
    lightGreen: 'B3FFDA',
    blueSecondary: '#7AD7FF',
    lightPurple: '#AA1EF5',
    darkPurple: '#312737',
    brand: '#F3E8DA',
  },

  borderWidths: {
    ui: '1px',
    icons: '2px',
  },

  breakpoints: {
    xs: '320px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },

  buttonVariants: {
    primary: '#ee3932',
    white: '#FFFFFF',
    dark: '#13294e',
  },

  inputSize: {
    sm: {
      desktop: { height: '2rem', fontSize: '0.875rem', borderRadius: '1rem' },
      mobile: { height: '1.75rem', fontSize: '0.75rem', borderRadius: '0.875rem' },
    },
    md: {
      desktop: { height: '3rem', fontSize: '1rem', borderRadius: '1.5rem' },
      mobile: { height: '2.5rem', fontSize: '0.8375rem', borderRadius: '1.25rem' },
    },
    lg: {
      desktop: { height: '4rem', fontSize: '1.125rem', borderRadius: '2rem' },
      mobile: { height: '3.25rem', fontSize: '0.875rem', borderRadius: '1.625rem' },
    },
  },

  scales: {
    xs: '0.375',
    sm: '0.4375',
    md: '0.5',
    lg: '0.5625',
    xl: '0.8125',
    xxl: '0.9375',
  },

  shadows: {
    small: '0.125rem 0.25rem 1rem rgba(0, 0, 0, 0.125)',
    normal: '0.25rem 0.5rem 2rem rgba(0, 0, 0, 0.125)',
  },

  transitions: {
    short: '0.125s ease-in',
    normal: '0.25s ease-in',
    long: '0.5s ease-in',
  },

  zIndex: {
    navbar: 100,
    surface: 1,
    layeredGraphic: 2,
    layeredContent: 3,
    aboveLayers: 4,
  },
};
