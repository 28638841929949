import React, { useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Text from '@components/text';
import Cookies from 'js-cookie';
import { debounce } from '@helpers/debounce';
import { RichTextBlock } from 'prismic-reactjs';
import NewsletterForm from './form';
import {
  NewsletterWrapper,
  NewsletterHeader,
  NewsletterTitle,
  NewsletterContainer,
} from './newsletter-modal.styled';

interface NewsletterModalProps {
  offsetAmount: number;
}

export interface NewsletterData {
  prismicGeneralConfig: {
    data: {
      newsletter_title?: {
        text: string;
      };
      newsletter_description?: {
        richText: RichTextBlock[];
      };
      newsletter_button_label?: string;
      newsletter_confirmation_title?: {
        text: string;
      };
      newsletter_confirmation_message?: {
        richText: RichTextBlock[];
      };
      newsletter_legal_text?: {
        richText: RichTextBlock[];
      };
    };
  };
}

const NewsletterModal = ({ offsetAmount }: NewsletterModalProps): JSX.Element => {
  const modalRef = useRef<HTMLDivElement>(null);
  const cookieName = 'newsletterModal';
  const [displayModal, setDisplayModal] = useState(true);

  // cms query
  const {
    prismicGeneralConfig: {
      data: {
        newsletter_title,
        newsletter_description,
        newsletter_button_label,
        newsletter_confirmation_title,
        newsletter_confirmation_message,
        newsletter_legal_text,
      },
    },
  }: NewsletterData = useStaticQuery(
    graphql`
      query NewsletterQuery {
        prismicGeneralConfig {
          ...GeneralConfigFragment
        }
      }
    `
  );

  // check if cookie is set
  useEffect(() => {
    const cookieVal = Cookies.get(cookieName);
    if (cookieVal === 'false') {
      setDisplayModal(false);
    }
  }, [displayModal]);

  // hide modal on scroll
  useEffect(() => {
    const loadScrollPosition = window.scrollY;
    const modal = modalRef.current;

    const handleScroll = debounce(() => {
      const scrollPosition = loadScrollPosition - window.scrollY;
      if (
        modal &&
        !modal.contains(document.activeElement) &&
        (scrollPosition >= 100 || scrollPosition <= -100)
      ) {
        window.removeEventListener('scroll', handleScroll);
        modal?.classList.add('animate-out');
        modal?.addEventListener('animationend', () => {
          setDisplayModal(false);
        });
      }
    }, 250);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <NewsletterWrapper isFixed={false} offsetAmount={offsetAmount} ref={modalRef}>
      <NewsletterContainer>
        {/* {displayModal && <CloseButton onClick={hideModal} />} */}
        <NewsletterTitle>
          <NewsletterHeader>
            <Text
              type="title"
              tag="h3"
              fontSize="heading6"
              fontColor="secondary"
              stringText={newsletter_title?.text}
            />
          </NewsletterHeader>
          <Text
            type="body"
            fontColor="primary"
            richText={newsletter_description?.richText}
            fontSize="body"
          />
        </NewsletterTitle>
        <NewsletterForm
          buttonLabel={newsletter_button_label || 'Subscribe'}
          legalRichText={newsletter_legal_text?.richText}
          confirmationMessage={newsletter_confirmation_message?.richText}
          confirmationTitle={newsletter_confirmation_title?.text}
        />
      </NewsletterContainer>
    </NewsletterWrapper>
  );
};

export default NewsletterModal;
