import React from 'react';
import { ImageProps } from '@components/image';
import generateId from '@helpers/generate-id';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { RichTextBlock } from 'prismic-reactjs';
import { defaultTheme } from '@styles/theme.styled';
import {
  Padder,
  SecurityGrid,
  SecurtiyImage,
  ContainerTitle,
  ContainerSubheading,
  Container,
} from './security-labels.styled';

const SecurityLabelIdGenerator = generateId();

export interface SecurityLabelItemProps {
  image?: ImageProps;
}

interface SecurityLabelProps {
  items: SecurityLabelItemProps[];
  title?: string;
  description?: RichTextBlock[];
  align?: string;
  backgroundAlignment?: string;
  displayBorder?: boolean;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
  itemsPerRow?: number;
}

const SecurityLabels = ({
  items,
  title,
  description,
  align,
  displayBorder,
  backgroundColor = 'white',
  backgroundAlignment = 'hidden',
  outerbackgroundColor = 'white',
  itemsPerRow = 3,
}: SecurityLabelProps): JSX.Element => {
  const isTextReceived = !!(title !== '' || description?.length);
  if (items?.length === 0 && isTextReceived) {
    return <></>;
  }

  return (
    <Section
      backgroundColor={backgroundColor}
      displayBorder={displayBorder}
      outerbackgroundColor={outerbackgroundColor}
      backgroundAlignment={backgroundAlignment}
    >
      <Wrapper>
        <Padder backgroundAlignment={backgroundAlignment}>
          {isTextReceived && (
            <Container backgroundColor={backgroundColor} align={align}>
              {!!title && (
                <ContainerTitle tag="h2" type="title" fontSize="heading3" stringText={title} />
              )}
              {!!description?.length && (
                <ContainerSubheading type="subheading" richText={description} />
              )}
            </Container>
          )}
          <SecurityGrid itemsPerRow={itemsPerRow}>
            {items.map(({ image }): JSX.Element => {
              return (
                <SecurtiyImage
                  key={SecurityLabelIdGenerator.next().value}
                  url={image?.url}
                  alt={image?.alt}
                  gatsbyImageData={image?.gatsbyImageData}
                  objectFit="contain"
                />
              );
            })}
          </SecurityGrid>
        </Padder>
      </Wrapper>
    </Section>
  );
};

export default SecurityLabels;
