import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Image, { ImageProps } from '@components/image';
import Text from '@components/text';
import Wrapper from '@components/wrapper.styled';
import generateId from '@helpers/generate-id';
import Section from '@components/section';
import getLinkConfig from '@helpers/get-link-config';
import {
  ImageContainer,
  Container,
  StyledLink,
  StyledGrid,
  TextContainer,
  StyledTitle,
} from './promotions.styled';

const PromotionsIdGenerator = generateId();

export interface PromotionsProps {
  items: PromotionsPropsItem[];
  itemsPerRow?: number;
}

export interface PromotionsPropsItem {
  image?: ImageProps;
  title?: {
    text: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
  link_label?: string;
  link?: {
    url?: string;
    uid?: string;
  };
}

const Promotions = ({ items, itemsPerRow = 3 }: PromotionsProps): JSX.Element => {
  return (
    <Section backgroundColor="white">
      <Wrapper>
        <StyledTitle
          type="title"
          tag="h2"
          fontSize="heading3"
          stringText="Flexible Solutions"
          margin="lg"
        />
        <StyledGrid itemsPerRow={itemsPerRow}>
          {items?.map(({ image, title, description, link_label, link }) => {
            const linkConfig = getLinkConfig(link);

            return (
              <Container key={PromotionsIdGenerator.next().value}>
                {image?.url && (
                  <ImageContainer>
                    <Image url={image?.url} alt={image?.alt || ''} />
                  </ImageContainer>
                )}
                <TextContainer>
                  {title?.text && (
                    <Text
                      tag="h3"
                      fontColor="dark"
                      type="title"
                      fontSize="heading6"
                      stringText={title.text}
                      margin="sm"
                    />
                  )}
                  {description?.richText && (
                    <Text
                      type="description"
                      richText={description?.richText}
                      fontColor="dark"
                      margin="md"
                    />
                  )}
                  {!!linkConfig.url && (
                    <StyledLink
                      title={link_label || 'Learn more'}
                      url={linkConfig.url}
                      fontColor="secondary"
                      isExternal={linkConfig.isExternal}
                      type="text"
                    />
                  )}
                </TextContainer>
              </Container>
            );
          })}
        </StyledGrid>
      </Wrapper>
    </Section>
  );
};

export default Promotions;
