import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { ImageProps } from '@components/image';
import generateId from '@helpers/generate-id';
import { Container, StyledSection, SlickContainer, SlickImage } from './carousel.styled';

const carouselIdGenerator = generateId();

export interface CarouselItemProps {
  image?: ImageProps;
}

interface CarouselProps {
  align?: string;
  items?: CarouselItemProps[];
}

const Carousel = ({ items = [], align }: CarouselProps): JSX.Element => {
  const settings = {
    className: 'slider variable-width',
    arrows: false,
    infinite: true,
    cssEase: 'linear',
    slidesToShow: 2,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: true,
    variableWidth: true,
    centerMode: true,
    speed: 20000,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };
  if (items?.length === 0 || !items[0].image?.url) {
    return <></>;
  }

  return (
    <StyledSection backgroundColor="white">
      <Container align={align}>
        <Slider {...settings}>
          {items?.map(({ image }) => (
            <SlickContainer key={carouselIdGenerator.next().value}>
              <SlickImage url={image?.url} alt={image?.alt} />
            </SlickContainer>
          ))}
        </Slider>
      </Container>
    </StyledSection>
  );
};

export default Carousel;
