import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import { ImageProps } from '@components/image';
import Text from '@components/text';
import { defaultTheme } from '@styles/theme.styled';
import getLinkConfig from '@helpers/get-link-config';
import { useTheme } from 'styled-components';
import getContrastColor from '@helpers/get-contrast-color';
import NotificationBanner from '@components/notification-banner';
import DownloadButtons, { ItemAppDownload } from '@components/download-buttons';
import AnchorNav, { AnchorType } from '@components/anchor-nav';
import {
  Header,
  Content,
  StyledImage,
  LinkButton,
  StyledWrapper,
  EyebrowWrapper,
  Badge,
} from './page-hero.styled';

export interface linkProps {
  url?: string;
  uid?: string;
  target?: string;
}

export interface notificationProps {
  url?: string;
  target?: string;
}

export interface PageHeroProps {
  image?: ImageProps;
  badge?: string;
  eyebrow?: string;
  title?: string;
  subtitle?: string;
  subheading?: RichTextBlock[];
  linkLabel?: string;
  link?: linkProps;
  size?: string;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
  backgroundImage?: ImageProps;
  left_align_content?: boolean;
  isShifted?: boolean;
  items?: ItemAppDownload[];
  secondaryNav?: AnchorType[];
  notificationMessage?: string;
  notificationIcon?: ImageProps;
  notificationUrl?: {
    url?: string;
    target?: string;
  };
  isNotificationDismissible?: boolean;
}

const DEFAULT_SIZE = 'large';
const DEFAULT_BACKGROUND_COLOR = 'white';
const DEFAULT_OUTER_BACKGROUND_COLOR = 'light';
const DEFAULT_LINK_LABEL = 'Request a Demo';
const TITLE_LENGTH_THRESHOLD = 56;

const PageHero = ({
  image,
  badge,
  eyebrow,
  title,
  subheading = [],
  subtitle,
  linkLabel,
  link,
  size = DEFAULT_SIZE,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
  outerbackgroundColor = DEFAULT_OUTER_BACKGROUND_COLOR,
  backgroundImage,
  left_align_content = false,
  isShifted = false,
  items,
  secondaryNav,
  notificationMessage,
  notificationIcon,
  notificationUrl,
  isNotificationDismissible = true,
}: PageHeroProps): JSX.Element => {
  if (!title && !image && !subheading.length) {
    return <></>;
  }

  const theme = useTheme();
  const linkConfig = getLinkConfig(link);
  const hasImage = !!(image?.gatsbyImageData || image?.url) && size === DEFAULT_SIZE;
  const titleSize = title && title?.length >= TITLE_LENGTH_THRESHOLD ? 'heading2' : 'heading1';
  const fontColor = backgroundImage?.url
    ? 'white'
    : getContrastColor(theme.backgroundColors[backgroundColor]);
  const eyebrowColor =
    backgroundColor === 'dark' || backgroundColor === 'primary' ? 'brand' : 'primaryLight';
  const subheadingColor =
    backgroundColor === 'dark' || backgroundColor === 'primary' ? 'white' : 'primary';
  const hasAnchorNav = !!secondaryNav?.length && !!secondaryNav[0].anchor_text;
  const hasUrl = notificationUrl?.url && notificationUrl?.url?.length > 0;

  return (
    <Header
      className="page-hero"
      outerbackgroundColor={outerbackgroundColor}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      isShifted={isShifted}
      $isLeftAligned={left_align_content}
      image={image}
    >
      <StyledWrapper $isLeftAligned={left_align_content}>
        {hasUrl && (
          <NotificationBanner
            notificationMessage={notificationMessage}
            notificationIcon={notificationIcon}
            notificationUrl={notificationUrl}
            isNotificationDismissible={isNotificationDismissible}
            margin="lg"
          />
        )}

        <Content backgroundImage={backgroundImage} image={image} secondaryNav={secondaryNav}>
          <EyebrowWrapper>
            {eyebrow && (
              <Text
                type="eyebrow"
                stringText={eyebrow}
                fontColor={eyebrowColor}
                margin={undefined}
              />
            )}
            {badge && <Badge>{badge}</Badge>}
          </EyebrowWrapper>
          {title && (
            <Text
              fontSize={titleSize}
              type="title"
              tag="h1"
              stringText={title}
              fontColor={fontColor}
              margin="md"
            />
          )}
          {subheading.length > 0 && (
            <Text type="subheading" richText={subheading} fontColor={subheadingColor} margin="lg" />
          )}
          {linkConfig.url !== '' && (
            <LinkButton
              type="button"
              url={linkConfig.url}
              title={linkLabel || DEFAULT_LINK_LABEL}
              isExternal={linkConfig.isExternal}
              backgroundImage={backgroundImage}
              target={link?.target || '_self'}
            />
          )}
          {!!subtitle && (
            <Text fontSize="subheading" type="title" stringText={subtitle} fontColor={fontColor} />
          )}
          {!!items?.length && <DownloadButtons items={items} />}
        </Content>
        {hasImage && (
          <StyledImage
            gatsbyImageData={image.gatsbyImageData}
            url={image.url}
            alt={image.alt || ''}
            objectFit="contain"
            loading="eager"
          />
        )}
      </StyledWrapper>
      {hasAnchorNav && <AnchorNav secondary_nav={secondaryNav} />}
    </Header>
  );
};

export default PageHero;
