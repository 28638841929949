import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales, generateScalingQueries } from '@helpers/generate-media-queries';
import { createTextLinkCSS } from '@components/link/link.styled';

export const StyledDescription = styled.div<{
  fontColor: keyof typeof defaultTheme.colors;
  fontSize: keyof typeof defaultTheme.fontSize;
  margin: keyof typeof defaultTheme.spacing | undefined;
}>`
  ${({ theme, fontSize, fontColor = 'dark', margin }) => {
    const bottomMargin: string = margin ? theme.spacing[margin] : '0';

    return css`
      font-family: ${theme.fontFamily.additional};
      line-height: ${theme.lineHeights.lg};
      color: ${theme.colors[fontColor]};
      margin-bottom: ${bottomMargin};
      ${generateScalingQueries('margin-bottom', bottomMargin, theme)}

      ${generateScales(
        'font-size',
        theme.fontSize[fontSize].mobile,
        theme.fontSize[fontSize].desktop,
        theme
      )}

      p + p {
        ${generateScales(
          'margin-top',
          theme.fontSize[fontSize].mobile,
          theme.fontSize[fontSize].desktop,
          theme
        )}
      }

      a {
        ${() => createTextLinkCSS(theme)}
        font-family: inherit;
        font-weight: normal;
        background-position: 100% 95%;
        background-size: 100% 0.1rem;

        :hover,
        :focus {
          background-size: 0% 0.1rem;
        }

        &::after {
          display: none;
        }
      }
    `;
  }}
`;
