import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '@layouts/default-layout';
import SEO from '@components/seo';
import renderModule from '@components/module';
import generateId from '@helpers/generate-id';
import { AnchorType } from '@components/anchor-nav';

const indexPageIdGenerator = generateId();

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export interface MainPageInterface {
  prismicGeneralConfig: {
    data: {
      page_name?: string;
      favicon?: {
        url?: string;
      };
      logo?: {
        alt: string;
        url: string;
      };
    };
  };
  allPrismicPage: {
    nodes: {
      last_publication_date: string;
      data: {
        layout_type: 'dark' | 'light';
        page_title: {
          text: string;
        };
        meta_description: {
          text: string;
        };
        meta_image: {
          url: string;
          alt: string;
        };
        secondary_nav: AnchorType[];
        body: [];
      };
    }[];
  };
}

const IndexPage: React.FC = () => {
  const {
    allPrismicPage,
    prismicGeneralConfig: {
      data: { favicon },
    },
  }: MainPageInterface = useStaticQuery(
    graphql`
      query MainPageQuery {
        prismicGeneralConfig {
          ...GeneralConfigFragment
        }
        allPrismicPage(filter: { uid: { eq: "home" } }) {
          nodes {
            ...PageFragment
          }
        }
      }
    `
  );

  const { page_title, meta_description, meta_image } = allPrismicPage.nodes[0].data;

  return (
    <Layout layoutType={allPrismicPage.nodes[0].data.layout_type}>
      <SEO
        title={page_title.text}
        faviconHref={favicon?.url || ''}
        description={meta_description.text}
        image={meta_image}
      />
      <Container>
        {allPrismicPage.nodes[0].data.body.map(
          (module) =>
            module && (
              <React.Fragment key={indexPageIdGenerator.next().value}>
                {renderModule(
                  module,
                  allPrismicPage.nodes[0].last_publication_date,
                  allPrismicPage.nodes[0].data.secondary_nav
                )}
              </React.Fragment>
            )
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
