import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import Text from '@components/text';
import Image from '@components/image';
import Grid from '@components/grid';

export const SecurityGrid = styled(Grid)`
  ${({ theme }) => css`
    column-gap: 3rem;
    row-gap: 3rem;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      row-gap: 3rem;
      column-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      row-gap: 2rem;
      column-gap: 2rem;
    }
  `}
`;

interface PadderProps {
  backgroundAlignment?: string;
}

export const Padder = styled.div<PadderProps>`
  ${({ theme, backgroundAlignment = 'hidden' }) => css`
    padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '8rem'};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '4rem'};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '3rem'};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '4rem'};
    }
  `}
`;

export const ContainerTitle = styled(Text)``;
export const ContainerSubheading = styled(Text)``;

export const Container = styled.div<{
  align?: string;
  backgroundColor: keyof typeof defaultTheme.backgroundColors;
}>`
  ${({ align = 'center', backgroundColor = 'white', theme }) => css`
    width: 100%;
    max-width: 40rem;
    margin: 0 auto 4rem auto;
    text-align: ${align};

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
      margin: 0 auto 2.5rem auto;
    }

    ${(backgroundColor === 'dark' || backgroundColor === 'primary') &&
    css`
      color: white;
      ${ContainerTitle}, ${ContainerSubheading} {
        color: white;
      }
    `};
  `}
`;

export const SecurtiyImage = styled(Image)`
  ${({ theme }) => css`
    max-height: 6rem;
    max-width: 12rem;
    align-self: center;
    margin: 0;

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      max-height: 4.5rem;
      max-width: 9rem;
    }
  `}
`;
