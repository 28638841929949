import styled from 'styled-components';

const StyledInput = styled.input`
  ${({ theme }) => `
    border: 1px solid ${theme.colors.dark};
    background-color: ${theme.colors.white};
    color: ${theme.colors.dark};
    outline: none;
    font-family: ${theme.fontFamily.text};
    transition: all ${theme.transitions.normal};

    ::placeholder {
      color: ${theme.colors.dark};
    }

    :focus {
      border-color: ${theme.colors.dark};
    }
  `}
`;

export default StyledInput;
