import styled, { css } from 'styled-components';
import Text from '@components/text';
import { generateScales } from '@helpers/generate-media-queries';
import { wrapperWidths, wrapperPadding } from '@components/wrapper.styled';
import { underlineTransition } from '@components/link/link.styled';
import { StyledMediumButton } from '@components/button/button.styled';

export const CookieText = styled(Text)`
  ${({ theme }) => css`
    display: block;
    color: ${theme.colors.darkPurple};
    font-size: 1rem;
    font-family: ${theme.fontFamily.additional};

    a {
      color: ${theme.colors.lightPurple};
      ${underlineTransition}
    }
  `}
`;

export const CookieConsentWrapper = styled.div`
  ${({ theme }) => css`
    position: fixed;
    left: 50%;
    bottom: 1rem;
    z-index: 999;
    width: ${wrapperWidths.xxl};
    transform: translateX(-50%);
    padding: 0 0;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      width: ${wrapperWidths.xl};
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      width: ${wrapperWidths.lg};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      width: ${wrapperWidths.md};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      position: relative;
      bottom: unset !important;
      top: unset;
      width: 100%;
    }

    .consent-container {
      line-height: ${theme.lineHeights.lg};
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 2rem;
      background: ${theme.colors.brand};
      box-shadow: -0.25rem -0.5rem 2rem rgba(0, 0, 0, 0.2);
      font-family: ${theme.fontFamily.display};
      font-size: 1rem;
      border-radius: 0.5rem;
      font-weight: 400;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
        justify-items: flex-start;
      }

      @media only screen and (max-width: ${theme.breakpoints.sm}) {
        padding: 1rem 0.25rem;
        margin-bottom: 0;
        border-radius: 0;
      }
    }

    .consent-container {
      padding: 1.25rem 2rem;
    }

    .consent-btn-wrapper {
      display: flex;
      column-gap: 0.75rem;

      @media only screen and (max-width: ${theme.breakpoints.md}) {
        width: 100%;
        padding: 0 ${wrapperPadding};
        margin-top: 1.5rem;
      }
    }
  `}
`;

export const StyledButton = styled(StyledMediumButton)`
  ${({ theme }) => css`
    background: ${theme.colors.secondary};
    border-color: ${theme.colors.secondary};
    color: ${theme.colors.white};
    font-weight: 500;
    white-space: nowrap;
    ${generateScales('font-size', theme.fontSize.body.mobile, theme.fontSize.body.desktop, theme)}

    &:hover {
      background: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
    }
  `}
`;
