import styled, { css } from 'styled-components';
import { defaultTheme } from '@styles/theme.styled';
import { generateScales } from '@helpers/generate-media-queries';
import LinkComponent from '@components/link';
import Grid from '@components/grid';
import Text from '@components/text';

export const TextContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      justify-content: flex-start;
      align-items: flex-start;
    }
  `}
`;

export const StyledTitle = styled(Text)`
  ${({ theme }) => css`
    text-align: center;
    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
    }
  `}
`;
export const StyledGrid = styled(Grid)`
  ${({ theme }) => css`
    @media only screen and (max-width: ${theme.breakpoints.md}) {
      display: flex;
      flex-wrap: wrap;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      row-gap: 3rem;
      justify-content: flex-start;
    }
  `}
`;

export const ImageContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 6.5rem;
    margin: 0 auto;
    padding: 1.5rem;
    max-width: 6.5rem;
    background-color: ${theme.colors.secondary};
    border-radius: 100%;
    width: 100%;
    margin-bottom: 1.5rem;

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      height: 4rem;
      max-width: 4rem;
      padding: 1.125rem;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    text-align: center;
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      flex-direction: row;
      text-align: left;
      column-gap: 1rem;
    }
  `}
`;

interface LinkComponentProps {
  fontColor: keyof typeof defaultTheme.colors;
}

export const StyledLink = styled(LinkComponent)<LinkComponentProps>`
  ${({ theme }) => {
    const { mobile, desktop } = theme.fontSize.body;
    return css`
      margin: 0;
      ${generateScales('font-size', mobile, desktop, theme)}
      font-weight: 500;
      width: fit-content;
    `;
  }}
`;
