import styled, { css } from 'styled-components';
import LinkComponent from '@components/link';
import Text from '@components/text';
import Image from '@components/image';
import { defaultTheme } from '@styles/theme.styled';
import { wrapperPadding } from '@components/wrapper.styled';

interface BlockProps {
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
}

interface ContainerProps {
  backgroundAlignment?: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${({ theme, backgroundAlignment = 'hidden' }) => css`
    padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '8rem'};

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '4rem'};
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '3rem'};
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      padding-bottom: ${backgroundAlignment === 'hidden' ? '0' : '4rem'};
    }
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    text-align: center;
    max-width: 54rem;

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left;
      max-width: 100%;
    }
  `}
`;

export const Grid = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      grid-template-columns: 1fr 1fr;
      flex-direction: column;
      row-gap: 3rem;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      grid-template-columns: 1fr;
      row-gap: 4rem;
    }
  `}
`;

export const StyledLinkComponent = styled(LinkComponent)<BlockProps>`
  width: fit-content;
  ${({ theme, backgroundColor = 'white' }) => css`
    margin: ${theme.padding.md} auto 0;
    ${(backgroundColor === 'dark' || backgroundColor === 'primary') &&
    css`
      a {
        color: white;
      }
    `};
  `}
`;

export const BlockImage = styled(Image)`
  ${({ theme }) => css`


    div,
    img,
    picture {
      object-position: left center;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      margin 0 -${wrapperPadding};
      width: calc(100% + (${wrapperPadding} * 2));

      div,
      img,
      picture {
        object-fit: cover !important;
      }
    }
  `}
`;

export const BlockTitle = styled(Text)`
  margin-bottom: 0;
`;

export const BlockDescription = styled(Text)``;

export const Block = styled.div<BlockProps>`
  ${({ theme, backgroundColor = 'white' }) => css`
    padding: 0 2rem;
    border-right-width: 1px;
    border-right-style: solid;
    border-image: linear-gradient(
        to bottom,
        ${theme.colors.secondary},
        rgba(215, 65, 167, 0.5),
        ${theme.colors.transparent},
        ${theme.colors.transparent},
        ${theme.colors.transparent}
      )
      1 100%;

    &:last-child {
      border: none;
    }

    ${(backgroundColor === 'dark' || backgroundColor === 'primary') &&
    css`
      ${BlockTitle} {
        color: ${theme.colors.white};
      }

      ${StyledLinkComponent}, ${StyledLinkComponent} a {
        color: ${theme.colors.white};
      }

      ${BlockDescription} {
        color: ${theme.colors.brand};
      }
    `}

    ${BlockImage} + ${BlockTitle} {
      margin-top: 2rem;
    }

    ${BlockTitle} + ${BlockDescription} {
      margin-top: 1rem;
      display: block;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      padding: 0;

      :nth-child(odd) {
        padding-right: 1.5rem;
      }

      :nth-child(even) {
        border: none;
        padding-left: 1.5rem;
      }
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      border: none;

      :nth-child(odd) {
        padding-right: 0;
      }

      :nth-child(even) {
        padding-left: 0;
      }
    }
  `}
`;
