import React from 'react';
import { RichText } from 'prismic-reactjs';
import { TextComponentProps } from '@components/text/text-props.type';
import { StyledSubheading } from './subheading.styled';

const Subheading = ({
  fontColor = 'dark',
  fontSize = 'subheading',
  richText,
  margin,
  className,
}: TextComponentProps): JSX.Element => (
  <StyledSubheading fontSize={fontSize} fontColor={fontColor} className={className} margin={margin}>
    <RichText render={richText} />
  </StyledSubheading>
);

export default Subheading;
