import React, { useState } from 'react';
import Text from '@components/text';
import { RichTextBlock } from 'prismic-reactjs';
import {
  StyledForm,
  FormRow,
  StyledInput,
  HelperText,
  InputContainer,
  StyledButton,
  Fields,
  ConfirmationWrapper,
} from './newsletter-modal.styled';

export interface formProps {
  buttonLabel: string;
  legalRichText?: RichTextBlock[];
  confirmationTitle?: string;
  confirmationMessage?: RichTextBlock[];
}

const NewsletterForm = ({
  buttonLabel,
  legalRichText,
  confirmationTitle,
  confirmationMessage,
}: formProps): JSX.Element => {
  // hubspot form
  const portalId = '39489460';
  const formId = 'de32ea1f-d85b-426c-a3e2-e9786612e88c';
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  // states
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  // page info
  const currentURL = typeof window !== 'undefined' ? window.location.href : 'Unknown URL';
  const pageTitle = typeof window !== 'undefined' ? document.title : 'Unknown title';
  const cleanPageTitle = pageTitle.replace(/TripShot \| /i, '');

  const submitToHubspot = () => {
    return new Promise((resolve, reject) => {
      const date = Date.now();
      const data = {
        submittedAt: date,
        fields: [
          {
            name: 'firstname',
            value: firstName,
          },
          {
            name: 'lastname',
            value: lastName,
          },
          {
            name: 'email',
            value: email,
          },
          {
            name: 'company',
            value: companyName,
          },
        ],
        context: {
          pageUri: currentURL,
          pageName: cleanPageTitle,
        },
      };
      const final_data = JSON.stringify(data);
      const xhr = new XMLHttpRequest();

      xhr.open('POST', url);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else if (xhr.status === 400) {
            reject(new Error(xhr.statusText));
          } else if (xhr.status === 403) {
            reject(new Error(xhr.statusText));
          } else {
            reject(new Error(xhr.statusText));
          }
        }
      };
      xhr.send(final_data);
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitted(true);
    if (!firstName || !lastName || !companyName || !email) {
      e.preventDefault();
    } else {
      e.preventDefault();
      submitToHubspot()
        .then(() => setDisplayConfirmation(true))
        .catch((err) => console.error(err));
    }
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit}>
        <Fields>
          <FormRow>
            <InputContainer>
              <StyledInput
                name="firstname"
                type="text"
                value={firstName}
                onChange={setFirstName}
                placeholder="First Name"
                isInvalid={isSubmitted && firstName === ''}
                disabled={displayConfirmation}
              />
              {isSubmitted && firstName === '' && (
                <HelperText>Please enter a first name</HelperText>
              )}
            </InputContainer>
            <InputContainer>
              <StyledInput
                name="lastname"
                type="text"
                value={lastName}
                onChange={setLastName}
                placeholder="Last Name"
                isInvalid={isSubmitted && lastName === ''}
                disabled={displayConfirmation}
              />
              {isSubmitted && lastName === '' && <HelperText>Please enter a last name</HelperText>}
            </InputContainer>
          </FormRow>
          <FormRow>
            <InputContainer>
              <StyledInput
                name="email"
                type="email"
                value={email}
                onChange={setEmail}
                placeholder="Email Address"
                isInvalid={isSubmitted && email === ''}
                disabled={displayConfirmation}
              />
              {isSubmitted && email === '' && <HelperText>Please enter a email address</HelperText>}
            </InputContainer>
            <InputContainer>
              <StyledInput
                name="company"
                type="text"
                value={companyName}
                onChange={setCompanyName}
                placeholder="Company Name"
                isInvalid={isSubmitted && companyName === ''}
                disabled={displayConfirmation}
              />
              {isSubmitted && companyName === '' && (
                <HelperText>Please enter a last name</HelperText>
              )}
            </InputContainer>
          </FormRow>
        </Fields>
        <StyledButton
          type="submit"
          label={buttonLabel}
          variant="dark"
          size="medium"
          disabled={displayConfirmation}
        />
        {legalRichText && (
          <Text type="body" fontColor="muted" richText={legalRichText} fontSize="extraSmall" />
        )}
      </StyledForm>
      {displayConfirmation && (
        <ConfirmationWrapper>
          <Text
            type="title"
            tag="h3"
            fontSize="heading6"
            fontColor="primary"
            stringText={confirmationTitle}
          />
          <Text type="body" fontColor="primary" richText={confirmationMessage} fontSize="body" />
        </ConfirmationWrapper>
      )}
    </>
  );
};

export default NewsletterForm;
