import React from 'react';
import Arrow from '@components/svg-icons/arrow';
import Image, { ImageProps } from '@components/image';
import { defaultTheme } from '@styles/theme.styled';
import {
  StyledLinkWrapper,
  StyledTextLink,
  StyledButtonLink,
  StyledButtonLabel,
  ExternalTextLink,
  ExternalButtonLink,
  ImageContainer,
} from './link.styled';

interface TextLinkProps {
  type?: string;
  title: string;
  url: string;
  className?: string;
  isExternal?: boolean;
  displayArrow?: boolean;
  target?: string;
  arrowSize?: number;
  icon?: ImageProps;
  arrowFill?: keyof typeof defaultTheme.colors;
  arrowStroke?: string;
  arrowContainerStroke?: string;
}

const LinkComponent: React.FC<TextLinkProps> = ({
  type = 'text',
  title,
  url,
  className = '',
  isExternal,
  displayArrow = true,
  target,
  arrowSize = 36,
  arrowFill = 'primary',
  arrowStroke = 'white',
  arrowContainerStroke = 'primary',
  icon,
}) => {
  const adjustedRoute = url === '/home' ? '/' : url;

  if (type === 'text') {
    return isExternal ? (
      <StyledLinkWrapper className={className}>
        <ExternalTextLink href={adjustedRoute} target={target}>
          {title}
        </ExternalTextLink>
      </StyledLinkWrapper>
    ) : (
      <StyledLinkWrapper className={className}>
        <StyledTextLink to={adjustedRoute}>{title}</StyledTextLink>
      </StyledLinkWrapper>
    );
  }

  return isExternal ? (
    <ExternalButtonLink
      href={adjustedRoute}
      className={className}
      $arrowSize={arrowSize}
      displayArrow={displayArrow}
      target={target}
    >
      {icon && (
        <ImageContainer>
          <Image
            gatsbyImageData={icon?.gatsbyImageData}
            url={icon?.url}
            alt={icon?.alt || ''}
            objectFit="contain"
          />
        </ImageContainer>
      )}
      <StyledButtonLabel>{title}</StyledButtonLabel>
      {displayArrow && (
        <Arrow
          containerStroke={arrowContainerStroke}
          width={arrowSize}
          height={arrowSize}
          stroke={arrowStroke}
          fill={arrowFill}
        />
      )}
    </ExternalButtonLink>
  ) : (
    <StyledButtonLink
      to={adjustedRoute}
      displayArrow={displayArrow}
      className={className}
      $arrowSize={arrowSize}
    >
      {icon && (
        <ImageContainer>
          <Image
            gatsbyImageData={icon?.gatsbyImageData}
            url={icon?.url}
            alt={icon?.alt || ''}
            objectFit="contain"
          />
        </ImageContainer>
      )}
      <StyledButtonLabel>{title}</StyledButtonLabel>
      {displayArrow && (
        <Arrow
          containerStroke={arrowContainerStroke}
          width={arrowSize}
          height={arrowSize}
          stroke={arrowStroke}
          fill={arrowFill}
        />
      )}
    </StyledButtonLink>
  );
};

export default LinkComponent;
