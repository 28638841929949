import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Text from '@components/text';
import { ImageProps } from '@components/image';
import generateId from '@helpers/generate-id';
import Wrapper from '@components/wrapper.styled';
import Section from '@components/section';
import { defaultTheme } from '@styles/theme.styled';
import getLinkConfig from '@helpers/get-link-config';
import {
  Content,
  Block,
  BlockImage,
  BlockTitle,
  BlockDescription,
  Container,
  Grid,
  StyledLinkComponent,
} from './patterns.styled';

const blockIdGenerator = generateId();

interface PatternsProps {
  title?: string;
  items?: {
    image?: ImageProps;
    title?: {
      text: string;
    };
    description?: {
      richText: RichTextBlock[];
    };
  }[];
  backgroundAlignment?: string;
  displayBorder?: boolean;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
  link?: {
    uid?: string;
    url?: string;
  };
  linkLabel?: string;
}

const Patterns = ({
  title,
  items = [],
  link,
  displayBorder,
  backgroundColor = 'white',
  backgroundAlignment = 'hidden',
  outerbackgroundColor = 'white',
  linkLabel = 'Learn more about TripShot',
}: PatternsProps): JSX.Element => {
  const linkConfig = getLinkConfig(link);

  return (
    <Section
      backgroundColor={backgroundColor}
      displayBorder={displayBorder}
      outerbackgroundColor={outerbackgroundColor}
      backgroundAlignment={backgroundAlignment}
    >
      <Wrapper>
        <Container backgroundAlignment={backgroundAlignment}>
          <Content>
            {title && (
              <Text
                type="title"
                tag="h2"
                stringText={title}
                fontSize="heading2"
                margin="lg"
                fontColor="white"
              />
            )}
          </Content>
          <Grid>
            {items?.map(({ title: itemTitle, description, image }) => (
              <Block key={blockIdGenerator.next().value} backgroundColor={backgroundColor}>
                {!!(image?.gatsbyImageData || image?.url) && (
                  <BlockImage
                    gatsbyImageData={image?.gatsbyImageData}
                    url={image?.url}
                    alt={image?.alt || ''}
                    objectFit="contain"
                  />
                )}
                <BlockTitle
                  tag="h3"
                  type="title"
                  stringText={itemTitle?.text}
                  fontSize="heading5"
                />
                <BlockDescription type="description" richText={description?.richText} />
              </Block>
            ))}
          </Grid>
        </Container>
        {!!linkConfig.url && (
          <StyledLinkComponent
            type="text"
            backgroundColor={backgroundColor}
            url={linkConfig.url}
            title={linkLabel}
            isExternal={linkConfig.isExternal}
          />
        )}
      </Wrapper>
    </Section>
  );
};

export default Patterns;
