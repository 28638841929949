import React from 'react';
import { navigate } from 'gatsby';
import {
  MobileNavWrapper,
  StyledSelect,
  NavWrapper,
  StyledLink,
  MobileTitle,
} from './anchor-nav.styled';

export interface AnchorType {
  anchor_text: string;
  anchor_link: string;
}
interface AnchorNavProps {
  secondary_nav?: AnchorType[];
}

const AnchorNav = ({ secondary_nav }: AnchorNavProps) => {
  return (
    <>
      <NavWrapper>
        {secondary_nav?.map(({ anchor_text, anchor_link }) => (
          <StyledLink key={anchor_text} href={anchor_link}>
            {anchor_text}
          </StyledLink>
        ))}
      </NavWrapper>
      <MobileNavWrapper>
        <MobileTitle>Jump To:</MobileTitle>
        <StyledSelect onChange={(e) => navigate(e.target.value)}>
          {secondary_nav?.map(({ anchor_text, anchor_link }) => (
            <option key={anchor_text} value={anchor_link}>
              {anchor_text}
            </option>
          ))}
        </StyledSelect>
      </MobileNavWrapper>
    </>
  );
};

export default AnchorNav;
