import styled, { css } from 'styled-components';
import Image from '@components/image';
import LinkComponent from '@components/link';
import Wrapper, { wrapperWidths } from '@components/wrapper.styled';
import { generateScales } from '@helpers/generate-media-queries';
import BackgroundMask from '@images/background-mask.svg';
import { headerHeight } from '@components/header/header.styled';
import { PageHeroProps } from './index';

export interface PageHeroStyleProps extends PageHeroProps {
  $isLeftAligned: boolean;
}

// positioning of mask used to create a responsive shape
const MaskShiftedPositioning = css`
  ${({ theme }) => css`
    mask-position: bottom 0% left calc(50% - (${wrapperWidths.xxl} * (0.5 / 2)));

    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      mask-position: bottom 0% left calc(50% - (${wrapperWidths.xl} * (0.5 / 2)));
    }

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      mask-position: bottom center;
      mask-size: 1720px 1440px;
    }

    @media only screen and (max-width: ${theme.breakpoints.md}) {
      mask-position: bottom center;
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      mask-position: bottom center;
    }
  `}
`;

export const EyebrowWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.375rem;
    margin-bottom: 1.5rem;
    ${generateScales('margin-bottom', '0.5rem', '1.25rem', theme)}

    p {
      margin-bottom: 0;
    }
  `}
`;

export const Badge = styled.div`
  ${({ theme }) => css`
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    border-radius: 4rem;
    background-color: ${theme.colors.accent};
    font-family: ${theme.fontFamily.additional};
    color: ${theme.colors.black};
    padding: 0 0.625rem;
    ${generateScales('height', '1.375rem', '1.5rem', theme)}
    font-weight: 600;
    ${generateScales(
      'font-size',
      theme.fontSize.extraSmall.mobile,
      theme.fontSize.extraSmall.desktop,
      theme
    )}
  `}
`;

export const StyledImage = styled(Image)`
  ${({ theme }) => css`
    z-index: 1;
    width: 100%;
    max-width: 32rem;
    height: auto;
    @media only screen and (max-width: ${theme.breakpoints.xl}) {
      max-width: 24rem;
    }
  `}
`;

export const Content = styled.div<PageHeroProps>`
  ${({ theme, secondaryNav }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;

    // Add padding if an anchor navigation is available
    ${!!secondaryNav?.length &&
    !!secondaryNav[0].anchor_text &&
    css`
      margin-bottom: 3rem;

      @media only screen and (max-width: ${theme.breakpoints.lg}) {
        margin-bottom: 8rem;
      }
    `}
  `}
`;

export const Header = styled.header<PageHeroStyleProps>`
  ${({
    theme,
    outerbackgroundColor,
    backgroundColor,
    image,
    isShifted,
    $isLeftAligned,
    backgroundImage,
  }) => {
    const hasImage = image?.url !== undefined && image.url !== '' && image.url !== null;

    const HeroGradientBorder = css`
      content: '';
      z-index: 0;
      display: content;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(270deg, #b3ffcf 25%, #a3e7ff 50%, #aa1ef5 75%);
      mask-image: url('${BackgroundMask}');
      mask-repeat: no-repeat;
      mask-size: 3440px 2880px;
    `;

    const MaskCenteredPositioning = css`
      mask-position: bottom center;
    `;

    return css`
      display: flex;
      position: relative;
      width: 100%;
      margin: 0;
      background-color: ${outerbackgroundColor
        ? theme.backgroundColors[outerbackgroundColor]
        : 'transparent'};
      ${generateScales('padding-top', '1.5rem', '3rem', theme)}
      ${generateScales('padding-bottom', '4rem', '8rem', theme)}

      &::before {
        content: '';
        z-index: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        ${backgroundColor &&
        css`
          background-color: ${theme.backgroundColors[backgroundColor]};
        `};
        ${backgroundImage &&
        css`
          background-image: url(${backgroundImage?.url});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        `};

        mask-image: url('${BackgroundMask}');
        mask-repeat: no-repeat;
        mask-size: 3440px 2880px;
        mask-position: bottom center;
        @media only screen and (max-width: ${theme.breakpoints.md}) {
          mask-size: 1720px 1440px;
          mask-position: bottom 0% left calc(50% - (${wrapperWidths.md} * (0.5 / 2)));
        }
        @media only screen and (max-width: ${theme.breakpoints.sm}) {
          mask-position: bottom center;
        }
      }

      ${Wrapper} {
        display: flex;
        margin-top: ${headerHeight};
        @media only screen and (max-width: ${theme.breakpoints.md}) {
          margin-top: 4rem;
        }

        ${hasImage === true
          ? css`
              flex-direction: row;
              ${generateScales('column-gap', '1.5rem', '3rem', theme)}
              @media only screen and (max-width: ${theme.breakpoints.lg}) {
                flex-direction: column;
                align-items: center;
                text-align: center;
                row-gap: 2rem;
                ${Content} {
                  align-items: center;
                }
                ${StyledImage} {
                  max-height: 25vh;
                  width: auto;
                }
              }
              @media only screen and (max-width: ${theme.breakpoints.sm}) {
                text-align: left;
                align-items: flex-start;
                ${Content} {
                  align-items: flex-start;
                }
              }
            `
          : css`
              flex-direction: column;
              align-items: center;
              text-align: center;

              ${Content} {
                max-width: 52rem;
                align-items: center;
              }

              @media only screen and (max-width: ${theme.breakpoints.md}) {
                text-align: left;
                align-items: flex-start;
                ${Content} {
                  align-items: flex-start;
                }
                ${backgroundImage &&
                css`
                  align-items: center;
                  h1,
                  h2,
                  h3,
                  h4,
                  h5,
                  h6,
                  p {
                    text-align: center;
                  }
                  ${Content} {
                    align-items: center;
                  }
                `};
              }
            `}
      }

      // adds a faux gradient border if enabled
      ${isShifted &&
      css`
        ::after {
          ${hasImage === true ? MaskShiftedPositioning : MaskCenteredPositioning};
          ${HeroGradientBorder}
          background: white;
          bottom: 0.5rem;
        }

        ::before {
          ${HeroGradientBorder}
          ${hasImage === true ? MaskShiftedPositioning : MaskCenteredPositioning};
        }
      `}

      ${$isLeftAligned &&
      css`
        @media only screen and (max-width: ${theme.breakpoints.lg}) {
          ${backgroundImage &&
          css`
            &::before {
              background-image: none !important;
            }
          `}
        }

        + section {
          ${generateScales('margin-top', '-10rem', '-18rem', theme)}
          background-color: transparent !important;
          h2 {
            color: ${theme.colors.white} !important;
          }
        }
      `}
    `;
  }}
`;

export const StyledWrapper = styled(Wrapper)<{ $isLeftAligned: boolean }>`
  ${({ theme, $isLeftAligned }) => css`
    align-items: flex-start;
    text-align: left;

    ${$isLeftAligned &&
    css`
      align-items: flex-start !important;
      text-align: left !important;

      ${generateScales('margin-bottom', '4rem', '8rem', theme)}

      ${Content} {
        max-width: 42rem !important;
        align-items: flex-start !important;
        text-align: left !important;
      }
    `}

    @media only screen and (max-width: ${theme.breakpoints.lg}) {
      text-align: center !important;
      align-items: center !important;

      ${$isLeftAligned &&
      css`
        ${Content} {
          max-width: 52rem !important;
          align-items: center !important;
          text-align: center !important;
        }
      `}
    }

    @media only screen and (max-width: ${theme.breakpoints.sm}) {
      text-align: left !important;
      align-items: flex-start !important;

      ${$isLeftAligned &&
      css`
        ${Content}, ${Content} h1, ${Content} p {
          max-width: 52rem !important;
          align-items: flex-start !important;
          text-align: left !important;
        }
      `}
    }
  `}
`;

export const LinkButton = styled(LinkComponent)<PageHeroProps>`
  ${({ theme, backgroundImage }) => {
    const hasBGImage =
      backgroundImage?.url !== undefined &&
      backgroundImage.url !== '' &&
      backgroundImage.url !== null;

    return css`
      ${hasBGImage &&
      css`
        box-shadow: ${theme.shadows.normal};
      `};

      &:hover {
        background-color: ${theme.colors.secondary};
        color: ${theme.colors.white};
      }
    `;
  }}
`;
