import React from 'react';
import { ImageProps } from '@components/image';
import Section from '@components/section';
import Wrapper from '@components/wrapper.styled';
import { defaultTheme } from '@styles/theme.styled';
import { RichTextBlock } from 'prismic-reactjs';
import generateId from '@helpers/generate-id';
import {
  Container,
  InnerContainer,
  BackgroundContainer,
  Title,
  Description,
  StyledGrid,
  Item,
  IconContainer,
  Icon,
  ItemTitle,
  ItemDescription,
} from './dashboard.styled';

const DashboardGenerator = generateId();

export interface DashboardProps {
  title?: string;
  description?: RichTextBlock[];
  items: DashboardPropsItem[];
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  itemsPerRow?: number;
}

export interface DashboardPropsItem {
  image?: ImageProps;
  title?: {
    text?: string;
  };
  description?: {
    richText: RichTextBlock[];
  };
}

const Dashboard = ({
  title,
  description,
  items,
  backgroundColor = 'light',
  itemsPerRow = 3,
}: DashboardProps): JSX.Element => {
  if (!title && !description) {
    return <></>;
  }
  return (
    <Section backgroundColor={backgroundColor}>
      <Wrapper>
        <Container>
          <InnerContainer>
            {title && (
              <Title
                type="title"
                tag="h2"
                fontSize="heading2"
                fontColor="primary"
                stringText={title}
              />
            )}
            {description && <Description type="subheading" richText={description} />}
            <StyledGrid rowNo={itemsPerRow}>
              {items.map((item) => (
                <Item key={DashboardGenerator.next().value}>
                  {item.image?.url && (
                    <IconContainer>
                      <Icon url={item.image?.url} alt={item.image?.alt || ''} />
                    </IconContainer>
                  )}
                  <div>
                    {item.title && (
                      <ItemTitle
                        tag="h3"
                        fontColor="primary"
                        type="title"
                        fontSize="large"
                        stringText={item.title?.text}
                      />
                    )}
                    {item.description && (
                      <ItemDescription type="description" richText={item.description?.richText} />
                    )}
                  </div>
                </Item>
              ))}
            </StyledGrid>
          </InnerContainer>
          <BackgroundContainer />
        </Container>
      </Wrapper>
    </Section>
  );
};

export default Dashboard;
