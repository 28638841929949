import React from 'react';
import { RichTextBlock } from 'prismic-reactjs';
import Wrapper from '@components/wrapper.styled';
import Text from '@components/text';
import { ImageProps } from '@components/image';
import { defaultTheme } from '@styles/theme.styled';
import getLinkConfig from '@helpers/get-link-config';
import Section from '@components/section';
import { Title } from '@components/text-and-image/text-and-image.styled';
import { GradientWrapper, LinkButton, Container, ImageContainer, Content } from './tout.styled';

interface ToutProps {
  title?: string;
  subheading?: RichTextBlock[];
  image?: ImageProps;
  link?: {
    url?: string;
    uid?: string;
    target?: string;
  };
  linkLabel?: string;
  layoutType?: string;
  backgroundAlignment?: string;
  displayBorder?: boolean;
  backgroundColor?: keyof typeof defaultTheme.backgroundColors;
  outerbackgroundColor?: keyof typeof defaultTheme.backgroundColors;
}

const Tout = ({
  title,
  subheading = [],
  image,
  link,
  displayBorder,
  backgroundColor = 'white',
  backgroundAlignment = 'hidden',
  outerbackgroundColor = 'white',
  linkLabel,
  layoutType = 'Row',
}: ToutProps): JSX.Element => {
  const hasImage: boolean = image?.url !== undefined && image?.url !== null && image?.url !== '';
  const isSubheadingExisting: boolean = subheading?.length > 0 && subheading[0].text !== '';
  const linkConfig = getLinkConfig(link);

  if (!title && !image && !isSubheadingExisting && !link) {
    return <></>;
  }

  return (
    <Section
      backgroundColor={backgroundColor}
      displayBorder={displayBorder}
      outerbackgroundColor={outerbackgroundColor}
      backgroundAlignment={backgroundAlignment}
    >
      <Wrapper>
        <GradientWrapper layoutType={layoutType}>
          <Container layoutType={layoutType}>
            {hasImage && (
              <ImageContainer
                url={image?.url}
                alt={image?.alt || ''}
                gatsbyImageData={image?.gatsbyImageData}
                objectFit="contain"
              />
            )}
            <Content hasImage={hasImage}>
              <div>
                {title && <Title type="title" tag="h2" fontSize="heading3" stringText={title} />}
                {isSubheadingExisting && (
                  <Text
                    type="description"
                    fontSize="subheading"
                    richText={subheading}
                    fontColor="primary"
                  />
                )}
              </div>
              {!!linkConfig.url && (
                <LinkButton
                  url={linkConfig.url}
                  type="button"
                  displayArrow={false}
                  title={linkLabel || 'Request a Demo'}
                  isExternal={linkConfig.isExternal}
                  target={link?.target || '_self'}
                />
              )}
            </Content>
          </Container>
        </GradientWrapper>
      </Wrapper>
    </Section>
  );
};

export default Tout;
