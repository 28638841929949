import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  StyledForm,
  StyledInput,
  StyledTextArea,
  FormRow,
  InputContainer,
  HelperText,
  StyledText,
  StyledSelect,
  StyledButton,
} from './contact-form.styled';

export interface OptionType {
  option: string;
}

export interface Elems {
  keyname: string;
  fallback: string;
  orgId: string;
  ts: string;
}

interface ContactFormProps {
  why_options: OptionType[];
  campaignId: string;
}

const sitekey = '6Lc_mQAVAAAAAK8aB3GZJMBTKG-JRwAO5IvdqoQR';

const ContactForm = ({ campaignId, why_options }: ContactFormProps): JSX.Element => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [whyContact, setWhyContact] = useState('');
  const [info, setInfo] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [captchaResponse, stCaptchaResponse] = useState('');
  const [disabledState, setDisabledState] = React.useState(true);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const setCaptchaValue = () => {
    const recaptchaValue = JSON.stringify(new Date().getTime());
    stCaptchaResponse(recaptchaValue);
    setDisabledState(false);
  };

  const setExpired = () => {
    stCaptchaResponse('');
    setDisabledState(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitted(true);

    if (!firstName || !lastName || !companyName || !email) {
      e.preventDefault();
    }
  };

  return (
    <>
      <StyledForm
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        method="POST"
        onSubmit={handleSubmit}
      >
        <StyledInput
          type="hidden"
          name="captcha_settings"
          value={`{"keyname":"Cometv2","fallback":"true","orgId":"00Df4000001eE2k","ts":"${captchaResponse}"}`}
        />
        <StyledInput type="hidden" name="oid" value="00Df4000001eE2k" />
        <StyledInput
          type="hidden"
          name="retURL"
          value={`https://tripshot.com/demo-thank-you/?type=${whyContact
            .toLowerCase()
            .replace(/ /g, '')}`}
        />
        <StyledInput type="hidden" name="lead_source" value="Website" />
        <StyledInput type="hidden" name="Campaign_ID" value={campaignId} />
        <StyledInput type="hidden" name="member_status" value="responded" />
        <StyledInput type="hidden" name="00Nf400000UqtMY" value={whyContact} />
        <FormRow>
          <InputContainer>
            <StyledText type="title" fontSize="eyebrow" stringText="First name" />
            <StyledInput
              name="first_name"
              type="text"
              value={firstName}
              onChange={setFirstName}
              placeholder="Your first name"
            />
            {isSubmitted && firstName === '' && <HelperText>Please enter a first name</HelperText>}
          </InputContainer>
          <InputContainer>
            <StyledText type="title" fontSize="eyebrow" stringText="Last name" />
            <StyledInput
              name="last_name"
              type="text"
              value={lastName}
              onChange={setLastName}
              placeholder="Your last name"
            />
            {isSubmitted && lastName === '' && <HelperText>Please enter a last name</HelperText>}
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <StyledText type="title" fontSize="eyebrow" stringText="Email" />
            <StyledInput
              name="email"
              type="email"
              value={email}
              onChange={setEmail}
              placeholder="Your email name"
            />
            {isSubmitted && email === '' && <HelperText>Please enter a email address</HelperText>}
          </InputContainer>
          <InputContainer>
            <StyledText type="title" fontSize="eyebrow" stringText="Phone No.  (Optional)" />
            <StyledInput
              name="phone"
              type="tel"
              value={phoneNumber}
              onChange={setPhoneNumber}
              placeholder="Your phone number"
            />
          </InputContainer>
        </FormRow>
        <FormRow>
          <InputContainer>
            <StyledText type="title" fontSize="eyebrow" stringText="Company" />
            <StyledInput
              name="company"
              type="text"
              value={companyName}
              onChange={setCompanyName}
              placeholder="Your company’s name"
            />
            {isSubmitted && companyName === '' && (
              <HelperText>Please enter your company&apos;s name</HelperText>
            )}
          </InputContainer>
          {why_options.length > 0 && (
            <>
              <InputContainer>
                <StyledText
                  type="title"
                  fontSize="eyebrow"
                  stringText="Why are you contacting TripShot?"
                />
                <StyledSelect items={why_options} onSelect={setWhyContact} />
                {isSubmitted && whyContact === '' && (
                  <HelperText>Please select a reason</HelperText>
                )}
              </InputContainer>
            </>
          )}
        </FormRow>
        <InputContainer>
          <StyledText
            type="title"
            fontSize="eyebrow"
            stringText="Additional information (Optional)"
          />
          <StyledTextArea
            name="description"
            value={info}
            onChange={setInfo}
            placeholder="Please add contextual information"
          />
        </InputContainer>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={sitekey}
          onChange={setCaptchaValue}
          onExpired={setExpired}
          style={{ margin: '0 auto' }}
        />
        <StyledButton
          type="submit"
          variant="primary"
          label="Get in touch"
          disabled={disabledState}
        />
      </StyledForm>
    </>
  );
};

export default ContactForm;
